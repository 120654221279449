// The clearfix mixin provides an easy way to contain floats
//
// Example usage:
// .wrapper {
//   @include clearfix;
// }

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

// Acknowledgements:
// Thierry Koblentz, cssmojo
// http://goo.gl/AQWvyH
