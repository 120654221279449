@import "../js/vendor/neat/app/assets/stylesheets/neat-helpers";

// Change the grid settings
// $column: 90px;
// $gutter: 30px;
// $grid-columns: 12;
$max-width: 1600px;

// Breakpoints
$desktop: new-breakpoint(min-width 1024px);
$tablet: new-breakpoint(max-width 1023px);
$tablet-up: new-breakpoint(min-width 481px);
$mobile: new-breakpoint(max-width 480px);