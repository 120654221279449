#owl-demo .item,
#owl-demo-two {
	padding: 30px 0px;
	margin: 10px;
	color: $black;
	font-weight: bold;
	text-align: center;
	background-repeat: no-repeat;
	height: 450px;
}
.customNavigation,
.customNavigation-two,
.customNavigation-catalog {
	text-align: center;
}

.customNavigation a,
.customNavigation-two a,
.customNavigation-catalog a {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
