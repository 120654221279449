// @import "config";
@import "config";
@import "mixins";

// Vendors
@import "../js/vendor/bourbon/app/assets/stylesheets/bourbon";
@import "grid-settings";
@import "../js/vendor/neat/app/assets/stylesheets/neat";
@import "../js/owl-carousel/owl.carousel.css";
@import "../js/owl-carousel/owl.theme.css";

// Base definitions
@import "standrdz";

// Generic
@import "generic";

// Structure
@import "structure/structure";
@import "structure/footer";

// Plugins
@import "plugins/owl-carousel";
@import "plugins/modal/modal";

// Main
@import "main";

// Components
@import "components/interactive-bg/interactive-bg";
@import "components/carousels";

// ------------------------------------
// Media Queries ----------------------
// ------------------------------------

body {
	position: relative;
}

@media (max-width: 599px) {
	// Plugins
	@import "plugins/modal/modal_t";
}

@media (max-width: 1199px) {
	// Components
	@import "components/interactive-bg/interactive-bg_m";
}

@media (min-width: 600px) {
	@import "plugins/modal/modal_d";
}
