.hidden {
	background: none;
	transform: none !important;
}

.display-none {
  display: none;
}

.block {
	display: block !important;
}

.none {
	display: none !important;
}
