.barn-mobile,
.garage-mobile {
	background-size: 200%;
	height: 849px;
	position: absolute;
	top:0;
	width: 100%;
	transition: all 100ms ease-in-out;

	&.center {
		background-position: center center;
		transition: all 100ms ease-in;
	}
	&.right {
		background-position: right center;
		transition: all 100ms ease-in-out;
	}
	&.left {
		background-position: left center;
		transition: all 100ms ease-in-out;
	}
}

.barn-mobile {
	background: url(img/base.jpg) left center no-repeat;
}

.garage-mobile {
	background: url(img/garage.jpg) left center no-repeat;
}
