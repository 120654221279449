.barn {
	position: relative;
	height: 849px;
}

.garage {
	position: relative;
	height: 849px;
}

.interactive-content {
	position: absolute;
	top: 100px;
	left: 200px;
	width: 100%;
	height: 849px;
}

[class^="icon-"], [class*=" icon-"] {
	font-size: 2em;
	color: $white;
	text-shadow: 0 0 10px $black;
}
