$black: #000000;
$white: #ffffff;
$charcoal: #323232;
$darkoal: #2b2f32;
$blue-text : #075676;

$button-unit: 1em;

.serif {
  font-family: "Georgia", serif;
}

@mixin font($typeface, $type, $style, $color) {
  font-family: $typeface, $type;
  font-style: $style;
  color: $color;
}
