
// Character Section + catalog
// ***************************************
.personnages-container {
	background: url(#{$imgPath}personnages-bg.png) top center no-repeat #b4d6eb;
	padding-bottom: 70px;

}

.personnages,
.catalogue {
	position: relative;

	h2 {
		padding-top: 1em;
	}

	h3 {
		font-size: 1.25em;
		font-weight: normal;
		margin-bottom: 1em;
		color: $charcoal;
		padding: 60px 0 10px 0;

		&.luc {
			padding-top: 1em;
			padding-bottom: 9em;
		}
	}

	.btn.prev,
	.btn.next,
	.btn.prev-catalog,
	.btn.next-catalog {
		position: absolute;
		font-size: $button-unit*3;
		background: white;
		width: $button-unit*1.3;
		height: $button-unit*1.3;
		line-height: $button-unit - .1;
		display: inline-block;
		border: 7px solid $blue-text;
		border-radius: 50%;
		text-align: center;
		box-shadow: 0 0 30px 5px rgba(0,0,0,0.3);
		cursor: pointer;
		color: $blue-text;
		z-index: 100;

		&:hover {
			transition: all 100ms ease-out;
			transform: translateY(4px);
		}

		@include media($mobile) {
		display: none;
	  }
	}

	.btn.prev {
		left: 13.7%;
		top: 80%;

		@include media($tablet) {
				left:5%;
		}
	}

	.btn.prev-catalog {
		left:13.7%;
		top:45%;

		@include media($tablet) {
				left:5%;
		}
	}

	.btn.next {
		right: 13.7%;
		top: 80%;

		@include media($tablet) {
				right:5%;
		}
	}

	.btn.next-catalog {
		right:13.7%;
		top:45%;

		@include media($tablet) {
				right:5%;
		}
	}


	.owl-item:first-of-type .go-back {
		display: none;
	}

	.owl-item:first-child .item {
		width:90%;
		padding: 70px 20px 20px 20px;
		max-width:1100px;
	}

	.item {
		display: table;
		position: relative;
		max-width: 950px;
		padding: 70px 20px 20px 20px;
		margin: 0 15%;

		@include media($tablet) {
			width:80%;
			margin: 0 12%;
		}

	  @include media($mobile) {
		width:100%;
	  }

// Personnages dans slide intro des caroussel

		.intro-caroussel {
			position:absolute;
			top:0%;
			left:0%;
		}

		.plus {
			position: absolute;
			z-index:100;

			img {
				width: 60px;
				transition: all 125ms ease-in;
			}

			&:hover img {
				transition: all 250ms ease-out;
				transform: rotate(180deg) scale(1.3,1.3);
			}
		}

		.persos-luc {

			left:-10%;
			
			.plus {
				left: 20%;
				top: 60%;
			}

			@include media($mobile) {
				top:7em;
				width:200px;
			}
		}

		.persos-france {
			top:1em;
			left:7%;

			.plus {
				top: 10%;
				left: 10%;
			}

			@include media($mobile) {
				top:4em;
				width:200px;
				left: 50%;

				.plus {
					top: 10%;
					left: 60%;
				}
			}
		}

		.persos-chuck {
			top:0%;
			left:30%;

			.plus {
				top: 23%;
				left: 5%;
			}

			@include media($mobile) {
			top:-1em;
			left:15%;
			width:250px;
		}
		}

		.persos-daniel {
			top:3em;
			left:60%;

			.plus {
				top: 3%;
				left:50%;
			}

			@include media($mobile) {
				left:65%;
				width:150px;
			}
		}

		.persos-maranda {
			top:7em;
			left:70%;

			.plus {
				top: 25%;
				right: 15%;
			}

			@include media($mobile) {
			width:70%;
			left:25%;
			width:180px;
		}
		}

		.persos-jacques {
			top:12em;
			left:55%;

			.plus {
				top: 25%;
				left: 50%;
			}

			@include media($tablet) {
				top:7em;
				left:40%;
				width:140px;
			}

			@include media($mobile) {
				top:12em;
				left:65%;
				width:140px;
			}
		}

		.persos-twins {
			top:12em;
			left:19%;

			.plus {
				top: 40%;
				left: 25%;
			}

			@include media($mobile) {
				top:20em;
				width:350px;
			}
		}


		.persos-nicky {
			top:4em;
			left:18%;

			.plus {
				top: 50%;
				left: 50%;
			}

			@include media($mobile) {
				top:17em;
				width:170px;
			}
		}

		.persos-cleo {
			top:10em;
			left:-5%;

			.plus {
				top: 50%;
				left: 15%;
			}

			@include media($mobile) {
				left:0%;
				top:20em;
				width:210px;
				z-index:99;
			}
		}


		.persos-pierre {
			top:1em;
			left:20%;

			.plus {
				top: 20%;
				left: 25%;
			}

			@include media($mobile) {
				top:5em;
				left:15%;
				width:140px;
			}
		}

		.persos-francois {
			top:15em;
			left:30%;

			.plus {
				top: 40%;
				left: 45%;
			}

			@include media($mobile) {
				top:25em;
				left:40%;
				width:180px;
			}
		}

		.persos-lucie {
			top:5em;
			left:40%;

			.plus {
				top: 30%;
				left: 45%;
			}

			@include media($mobile) {
				top:2em;
				left:50%;
				width:200px;
			}
		}

		.persos-sophie {
			top:3em;
			left:67%;

			.plus {
				top: 30%;
				left: 10%;
			}

			@include media($tablet) {
				width:220px;
				left:70%;
				top:4em;
			}

			@include media($mobile) {
				width:220px;
				left:57%;
				top:12em;
			}
		}


		@include media($mobile) {
		margin: 0 0 !important;
		padding:0;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		vertical-align: middle;

		img.bioimage {
			-webkit-order: 1;
			order: 1;
			margin: 0 10%;
			max-width: 225px;
		}

	  }


		.go-back {
			position: absolute;
			top: 0;
			left: 0;
		}

		.biotext {
			display: table-cell;
			vertical-align: middle;

			@include media($mobile) {
				-webkit-order: 2;
			order: 2;
			font-size:0.8em;
		  }

			@include media($mobile) {
			display: block;
		  }

			&.right {
				width: 50%;
				text-align: left;
				padding-right: 5%;

				@include media($mobile) {
				width: 100%;
				padding: 4%;
			  }
			}

			&.left {
				width: 51%;
				text-align: left;
				padding-left: 8%;

				@include media($mobile) {
				width: 100%;
				padding: 4%;
			  }
			}

			h4 {
				margin-bottom: .75em;
				font-size: 1.85em;
				color: $blue-text;
				font-weight: 300;
			}

			p {
				font-size: 1.1em;
				color: $charcoal;
				font-weight: 300;
				line-height: 1.25em;
			}
		}
		.alignright {
			display: table-cell;
			vertical-align: middle;
		}
	}
}