// Rules that are shared across different screen sizes
body.scroll-hidden {
    overflow: hidden;
}

#modal-wrap {
    background: rgba(0, 0, 0, 0.5);
    @include transition(all 250ms ease-in-out);
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

#modal {
    // @include padding(10px);
    @include position(relative, null null null null);
    @include transition(all 500ms ease-in-out);
    background: $white;
    opacity: 0;
    text-align: center;


    &.popped {
        @include transition(all 500ms ease-in-out);
        opacity: 1;
        z-index: 1000;
    }

    .close {



    }
}
