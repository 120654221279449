// Vars
$imgPath : "img/";

// Functions
// -- Background
@function get-path-to-assets($imgPath){
  @return $imgPath;
}

body {
    font-size: 1;
}

.icon-plus:before {
	content: " ";
	background: url(#{$imgPath}plus.png);
	width: 82px;
	height: 82px;
	display: block;
}
