// Desktop Version
#modal {
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.75);
    @include padding(30px 30px 20px 20px);
    border: 6px solid $blue-text;
    padding: 3em;
    font-size: 1.25em;
    line-height: 1.3em;
    color: $charcoal;
}
