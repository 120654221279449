.site-footer {
	@include outer-container;
	padding: 5% 0;

	.partners-logos {
		padding: 0 5%;

		@include media($tablet) {
		  	padding:0;
		  }

		li {
			display: inline-block;
			margin: 0 1.5em;

			@include media($mobile) {
				display: block;
		  }
		}
	}
}
