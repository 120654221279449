/**
* NOTE: 
* This wonderful 1000+ lines of codes is a good example of what NOT to do when using dynamic stylesheet tools like SASS!
* There is a lot of scss files, but the project is still all in one big file... :(
*
* Features should be pulled one by one like personnages.scss
*/
body {
	background: $charcoal;
	font-family: 'Wellfleet', serif;
	text-align: center;
	overflow-x: hidden;
}

.page {
	position: relative;
	@include outer-container;
	overflow: hidden;
}

.background {
	z-index: -1;
	@include span-columns(12);
	position: absolute;
	top: 56px;
	width: 100%;
	max-width: 100%;
}

h2 {
	font-size: 3em;
	color: $blue-text;
	letter-spacing:1px;

	@include media($mobile) {
		font-size: 2.5em;
	}
}

/* Minor age conditions, after users select 12 years and less */
.age-minor {

	.hide-for-minors {
		display:none !important;
	}

	#btnNews_game {
		margin-left:34%;
	}
}

// Splash Page
// ***************************************
#splash {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 1000;
	background: $white;

	@include media($tablet) {
		position: absolute;
	}

	.splash-container {
		@include outer-container;
		position: relative;
		max-width: 1000px;
		height: 100%;

		.choice {
			@include span-columns(12);
			position: absolute;
			top: 0;
			bottom: 0;
			height: 100%;
			background:	url(#{$imgPath}splash/splashPage-fr-bg.jpg) top center no-repeat;
			
			body.english & {
				background-image: url(#{$imgPath}splash/splashPage-en-bg.jpg);
			}

			@include media($mobile) {
				background-image:	url(#{$imgPath}splash/splashPage-mobile-fr.jpg);
				background-size: contain;
				
				body.english & {
					background-image: url(#{$imgPath}splash/splashPage-mobile-en.jpg);
				}
			}

			p {
					position: absolute;
					z-index: 200000;
					top: 430px;
					cursor: pointer;
					font-size: 1.75em;
					display: block;

					@include media($tablet) {
						top: 448px;
					}

					@include media($mobile) {
						top: auto;
						margin-top: -10%;
					}

				&.question {
					top: 400px;
					position: relative;

					span {
						display: block;
						font-size: .75em;
						margin-top: 50px;

						@include media($mobile) {
							margin-top: 110px;
						}
					}
				}

				&.activate-minor{
					left: 22%;
					width: 226px;

					@include media($mobile) {
						left: 0;
						right: 0;
						top: 440px;
						width: 100%;
					}
				}

				&.activate-adult {
					right: 22%;
					width: 226px;

					@include media($mobile) {
						left: 0;
						right: 0;
						top: 570px;
						width: 100%;
					}
				}

				img {
					@include media($tablet) {
						width: 150px;
					}

					@include media($mobile) {
						
					}
				}
			}
		}
	}
}

// Site Header
// ***************************************
.site-header {
	height: 56px;
	background: $white;
	border-bottom: 7px solid #035374;
	position: relative;

	@include media($mobile) {
		height: 56px;
		transition: all 1s;
		max-height: 56px;

		&.expand {
			height: auto;
			transition: all 1s;
			max-height: 500px;
		}
	}
}

.burger {
	position: absolute;
	top: 11px;
	left: 10px;

	@include media($tablet-up) {
		display: none;
	}

	img {
		width: 34px;
		cursor: pointer;
	}
}

.site-navigation {
	@include media($mobile) {
		display: none;

		&.show {
			display: block;
		}
	}
}

#menu-menu-1 {
	li {
		display: inline-block;
		line-height: 49px;
		margin: 0 2.5%;
		font-size: 1.1em;

		@include media($mobile) {
			display: block;
		}

		a {
			color: $blue-text;

			&:hover {
				color:#C01B0B;
			}
		}
	}
}

.social {
	position: absolute;
	right: 10px;
	top: 15px;
	text-align: left;
	color: $blue-text;
	font-size: 1.2em;
}

// Synopsis Section
// ***************************************
.synopsis {
	color: $blue-text;
	background:	url(#{$imgPath}hero-bg.jpg) top center no-repeat #b4d6eb;
	position: relative;
	padding-bottom: 50px;

	.content {

		p {
			color: $charcoal;
			font-size: 1.25em;
			line-height: 1.35em;

			@include media($mobile) {
					font-size:1.1em;
				}
		}

		.header-logo {
			padding-top: 65px;

			@include media($mobile) {
					padding-top: 60px;
				}


			img {
				@include media($tablet) {
					width: 80%;
					max-width: 449px;
				}
			}

		}

		.persos-intro {
			margin-top: -63px;
			min-height: 345px;
			overflow: hidden;
			width: 100%;

			@include media($mobile) {
				min-height: 200px;
			}

			h3 {
				@include font(Georgia, serif, italic, $white);
			}

			.perso-block {
				display: inline-block;
			}

			.persos-intro__sophie {
				position: absolute;
				left: 24%;
				min-width: 260px;
				min-height: 370px;
				background:	url(#{$imgPath}hero-sophie.png) top left no-repeat;

				@include media($tablet) {
					left: 14%;
				}

				@include media($mobile) {
					top:19%;
					left:7%;
					background-size:70%;
				}
			}

			.persos-intro__luke {
				position: absolute;
				right: 16%;
				min-width: 260px;
				min-height: 370px;
				background:	url(#{$imgPath}hero-luke.png) top right no-repeat;

				img {
					float: right;
				}

				@include media($tablet) {
					right: 5%;
				}

				@include media($mobile) {
					right: -25%;
					top: 19.5%;
					background-size:85%;
				}
			}
		}

		.news {
			margin-bottom: 160px;
			@include outer-container;
			width: 600px;

			@include media($tablet) {
				width: 80%;
			}

			@include media($mobile) {
				margin:225px 10% 80px 10%;
			}

			.news-icon {
				@include span-columns(4);
				position: relative;
				height: 160px;
			
				p {
					text-align: center;
					width: 100%;
					color: $blue-text;
					font-size:1.1em;
					margin-top:-10px;

					span {
						color:$charcoal;
						font-size:0.8em;
					}
				}

				img.fx {
					transition: all 250ms ease-in-out;
					z-index: 10;

					&:hover {
						transition: all 125ms ease-out;
						transform: rotate(30deg) scale(1.1,1.1);
					}
				}

				.store-links {
					text-align:center;
					margin-top:5px;
					a {
						margin:5px auto 0;
						padding:0;
						line-height:0;
						display:block;
						border-radius: 10px;
						border:3px solid rgba(255,255,255,0);
						transition: border 125ms ease-out;
						width:150px;

						&:hover {
							border:3px solid rgba(255,255,255,1);
							transition: border 125ms ease-out;
						}
					}

				}

				@include media($mobile) {
				 	@include span-columns(12);
						margin-bottom: 1.5em;
				}
			}
		}

		.synopsis-text {
			margin: 0 20%;

			@include media($tablet) {
				margin: 0 10%;
			}
			@include media($mobile) {
				margin: 0 5%;
			}

			p {
				margin-top: 55px;
				padding-bottom: 40px;

				@include media($mobile) {
					margin-top: 25px;
					padding-bottom: 80px;
				}
			}
		}
	}
}

.catalogue h3 {
	color:$blue-text;
}


// Interactive
// ***************************************

#lieux {
	padding-top:1px;
	margin-top:-1px;
}
.parallax-viewport {
	overflow: hidden;
	width: 100%;
	height: 700px;
	position: relative;

	@include media($tablet) {
		display: none;
	}

	p {
		font-size: 1.3em;
		color: $blue-text;
		margin-bottom: 0.7em;
	}
}

.lieux-title {
		position: absolute;
		width: 100%;
		margin: 5em 0 0 0;

		@include media($tablet) {
			margin-top:2em;
			font-size:0.8em;
		}

		@include media($mobile) {
			margin-top:1.2em;
			font-size:0.6em;
		}
	}

.lieux-white h2, .lieux-white p{
	 	color: white;
	}

.layer {
	position: absolute;
	width: 2075px;
	overflow: hidden;
	left: -15%;

	@include media($mobile) {
		width: 300%;
		margin-left: -200%;
		overflow: hidden;
		height: 100%;
		left: auto;
		top: 0;

		img {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.car-animation {
		position:absolute;
		top:74.2%;
		left:27%;

		@include media($mobile) {
			display: none;
		}
	}

	.swing-animation {
		position:absolute;
		top:-5%;
		left:63%
	}

	.lights-animation {
		position:absolute;
		top:22.1%;
		left:31%;
	}

	.plus {
			position: absolute;

			img {
				transition: all 125ms ease-in;
			}

			&:hover img {
				transition: all 250ms ease-out;
				transform: rotate(180deg) scale(1.2,1.2);
			}
		}


	// Village pluses

	.v01 {
		top: 50%;
		left: 26%;
	}

	.v02 {
		top: 58%;
		right: 12%;
	}

	.v03 {
		top: 62%;
		left: 44%;
		z-index:50;
	}

	.v04 {
		top: 53%;
		left: 57%;
		z-index:50;
	}

	// Grange pluses

	.g01 {
		top: 58%;
		left: 59%;
		z-index:50;
	}

	.g02 {
		top: 80%;
		left: 45%;
		z-index:50;
	}

	.g03 {
		top: 40%;
		left: 28%;
		z-index:50;
	}

	// Salle de control pluses

	.c01 {
		top: 38%;
		left: 33%;
		z-index:50;
	}

	.c02 {
		top: 44%;
		left: 47%;
		z-index:50;
	}

	.c03 {
		top: 45%;
		left: 15%;
		z-index:50;
	}

	.c04 {
		top: 40%;
		left: 80%;
		z-index:50;
	}


}

.lieux-mobile {
	position: relative;
	overflow: hidden;

	@include media($tablet) {
		display: block;
	}

	@include media($desktop) {
		display: none;
	}

	.lieu-wrap {
		position: relative;
	}

	.move-left,
	.move-right {
		color: white;
		position: absolute;
		top: 50%;
	}

	.move-left {
		left: 5%;
	}

	.move-right {
		right: 10%;
	}

	.btn.prev,
	.btn.next {
		position: absolute;
		font-size: $button-unit*2;
		background: white;
		width: $button-unit*1.1;
		height: $button-unit*1.1;
		line-height: $button-unit - .2;
		display: inline-block;
		border: 3px solid $blue-text;
		border-radius: 50%;
		text-align: center;
		box-shadow: 0 0 30px 5px rgba(0,0,0,0.3);
		cursor: pointer;
		color: $blue-text;
		z-index: 100;

			&:hover {
				transition: all 100ms ease-out;
				transform: translateY(4px);
			}
		}

	.lieu {
		position: relative;
		left: 0%;
		width: 200%;

			.mobile-lieux-bg {
				position: absolute;
				width: 100%;
				height: auto;
				left: 0;
				z-index: -1;
			}

			&.left {
				left: -100%;
				transition: all 250ms ease-in-out;
			}

			&.center {
				left: -50%;
				transition: all 250ms ease-in-out;
			}

			&.right {
				right: 0;
				transition: all 250ms ease-in-out;
			}

			.plus {
				position:absolute;
			}


			// Village pluses
			.v01 {
				top: 50%;
				left: 26%;
			}
			.v02 {
				top: 58%;
				right: 12%;
			}

			.v03 {
				top: 62%;
				left: 44%;
				z-index:50;
			}

			.v04 {
				top: 53%;
				left: 57%;
				z-index:50;
			}

			// Grange pluses

	.g01 {
		top: 58%;
		left: 59%;
		z-index:50;
	}

	.g02 {
		top: 70%;
		left: 45%;
		z-index:50;
	}

	.g03 {
		top: 40%;
		left: 28%;
		z-index:50;
	}

	// Salle de control pluses

	.c01 {
		top: 38%;
		left: 33%;
		z-index:50;
	}

	.c02 {
		top: 44%;
		left: 47%;
		z-index:50;
	}

	.c03 {
		top: 45%;
		left: 15%;
		z-index:50;
	}

	.c04 {
		top: 40%;
		left: 80%;
		z-index:50;
	}

	}
}

// Videos
// ***************************************
.videos {
	padding: 0 5% 6% 5%;
	width: 100%;
	text-align: center;
	background:	url(#{$imgPath}videos-bg.jpg) bottom center no-repeat #98CEF0;

	iframe {
		width:23em;
		height:13em;
		border:7px solid $blue-text;
		border-radius:5px;
		margin:0.8em;

		@include media($mobile) {
			width: 90%;
		}
	}

	h2 {
		padding: 6% 0 7% 0;
	}

	br {
		display: none;
	}

	a {
		text-align: center;
		padding: 20px;
		display: inline-block;
	}
}

.video-onload {
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 999999;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0,0,0,0.8);

	.video-container {
		@include media($mobile) {
			position: absolute;
			margin-top: -150px;
			top: 50%;
			margin-left: -170px;
			left: 50%;
			width: 340px;
			height: 300px;
		}

		@include media($tablet-up) {
			position: absolute;
			margin-top: -240px;
			top: 50%;
			margin-left: -320px;
			left: 50%;
			width: 640px;
			height: 480px;
		}

		@include media($desktop) {
			position: absolute;
			margin-top: -300px;
			top: 50%;
			margin-left: -400px;
			left: 50%;
			width: 800px;
			height: 600px;
		}
	}
}

// Catalogue
// ***************************************
.catalogue {
	height: 649px;
	padding: 0 20%;
	background:	url(#{$imgPath}catalogue-bg.jpg) top center no-repeat;

	@include media($mobile) {
		height: auto;
		padding: 0 0 7% 0;
	}

	h2 {
		padding: 8% 0 13% 0;
	}

	h3 {
		font-size:1.4em;
		padding-top:1em;
	}

	p {
		font-size:0.9em;
		line-height:1.4em;

		a {
		text-decoration:underline;
		color:$blue-text;

		&:hover {
			text-decoration:none;
		}
	}


	}

	.item {
		display: table;
		position: relative;
		width: 100%;
		padding: 0 !important;
	}

	.catalogue-caroussel {
		img {
			width: 100%;
			max-width: 198px;
		}
	}
}

// Site Footer
// ***************************************

.site-footer {
	background: $white;

	.partners-logos {
		@include media($mobile) {
			padding:0;
		}
		.credits-column-full {
			
		}

		h3 {
			color:#707275;
			text-align:left;
			margin-top:2em;
			margin-bottom:0;
		}

		.credits-column {
			float:left;
			width:33%;
			text-align:center;
			min-width:250px;
			margin:0 auto;
			
			@media (max-width: 850px) {
				float:none;
				width:100%;
				min-width:auto;
			}
		}
	}

	img {
		max-height:35px;
		width:auto;
		margin:1em;

		@include media($mobile) {
			max-height: 20px;
		}
	}

	img.smaller-logo {
		max-height:27px;
	}

	a {
		color:$blue-text;
		text-decoration:underline;

		&:hover {
			text-decoration:none;
		}
	}

	p {
		font: normal 0.8em/1em Arial, Helvetica, sans-serif;
		margin:3em 0 0.8em;
	}

}

.closed {
	display: none;
}

.close {
	position:absolute;
	top: -50px;
	right: -50px;
	background: url(#{$imgPath}/icons/close.png) center center no-repeat transparent;
	width: 93px;
	height: 93px;
	padding-left: 100px;
	overflow: hidden;

	@include media($mobile) {
		top: -10px;
		right: -10px;
	}
}

.modal-content {
	@include media($mobile) {
		display: table;
		height: 100%;
		margin-top: -100px;
	}

	.generated {
		@include media($mobile) {
			vertical-align: middle;
			display: table-cell;
		}
	}
}
