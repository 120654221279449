body {
    margin: 0;
    font: 16px/1 sans-serif;
}

h1,
h2,
h3,
h4,
p,
blockquote,
figure,
ol,
ul {
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
}

main,
li {
    display: block;
}

h1,
h2,
h3,
h4 {
    font-size: inherit;
}

strong {
    font-weight: bold;
}

a,
button {
    color: inherit;
    transition: .3s;
}

a {
    text-decoration: none;
}

button {
    overflow: visible;
    border: 0;
    font: inherit;
    letter-spacing: inherit;
    background: none;
    cursor: pointer;
}

:focus {
    outline: 0;
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
}
